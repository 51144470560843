import React from "react";
import Layout from "../components/layout"

const Privacidad = () => {
    
    const brand = <strong>Inmobiliaria y Arrendadora Visa, S.A. de C.V.</strong>

    return (
        <Layout>
            <main className="row">
                <div className="col-12  m-auto py-5">
                    <h5 className="text-center">AVISO DE PRIVACIDAD</h5>
                    <br/>
                    <p className="text-center"><u><strong>A Nuestros Clientes y Proveedores:</strong></u></p>
                    <br/>
                    <div style={{textAlign:'justify'}}>
                        <p>En {brand} con domicilio en Bosque de lavanda, no. 60, int. 1, col. Bosque esmeralda, Atizapán de Zaragoza, C.P. 52930, Estado de México, nos comprometemos a proteger tu privacidad.</p>
                        <p>Este aviso de Privacidad detalla la información como usuario de nuestros servicios nos proporcionas, como la recabamos, lo que hacemos con ella y cómo la protegemos.</p>
                        
                        <p><strong>¿Con qué fines recabamos y utilizamos tus datos personales?</strong></p>
                        <p>Los datos personales recabados serán utilizados únicamente para las siguientes finalidades:</p>
                        <p><strong>Clientes</strong></p>
                        <ul>
                            <li>Proveer los servicios y productos requeridos por usted ante {brand}</li>

                            <li>  Para tramitar una autorización de crédito o financiamiento {brand}</li>
                            <li>  Por el otorgamiento de crédito o financiamiento por parte de {brand}</li>
                            <li>Informar sobre cambios o nuevos productos o servicios que estén relacionados con el contratado o adquirido por el cliente.</li>
                            <li>  Informar sobre el estado de los servicios o productos adquiridos por el cliente.</li>
                            <li>  Dar cumplimiento a obligaciones contraídas con nuestros clientes.</li>
                            <li>  Evaluar la calidad de servicio.</li>
                            <li>Realizar estudios internos para mejorar la calidad del servicio y productos proporcionados por {brand}</li>
                        </ul>

                        <p><strong>Datos Personales recabados</strong></p>

                        <p>Los datos personales recabados con los fines previstos en el presente Aviso de Privacidad son recabados directamente, Correo Electrónico u otras fuentes.</p>

                        <p><strong>Tipo de documentos a los cuales le damos tratamiento.</strong></p>

                        <ol>
                            <li>Nombre Completo y/o Razón Social</li>
                            <li>E-Mail</li>
                            <li>Teléfono de contacto (Casa, oficina, celular)</li>
                            <li>Dirección (calle, Número, Colonia, Ciudad, Estado, CP, País)</li>
                            <li>Identificación Oficial </li>
                            <li>Comprobante de Domicilio</li>
                            <li>CURP</li>
                            <li>Constancia de situación fiscal (RFC)</li>
                            <li>Acta Constitutiva y Poderes Legales</li>
                            <li>Alta ante SHCP</li>
                            <li>Escritura Pública de alguna propiedad.</li>
                        </ol>

                        <p><strong>Datos de Tarjeta de Crédito o Débito</strong></p>

                        <ol>
                            <li>Número de Tarjeta</li>
                            <li>Nombre de Titular</li>
                            <li>Fecha de Vencimiento</li>
                        </ol>

                        <p><strong>Información Financiera:</strong></p>
                        <ol>
                            <li>Estados Financieros Dictaminados</li>
                            <li>Caratulas bancarias</li>
                            <li>Declaración de Impuestos</li>
                            <li>Número de cuentas Bancarias e institución</li>
                            <li>Autorización para solicitar reporte en buro de Crédito.</li>
                        </ol>

                        <p>Toda la información será resguardada y protegida bajo las medidas de seguridad establecidas por el área de datos personales en apego a la Ley federal de Protección de datos Personales en Posesión de Particulares, con la finalidad de garantizar la protección a la misma.</p>

                        <p><strong>Datos personales sensibles</strong></p>

                        <p>Le informamos que para cumplir con las finalidades previstas en este aviso de Privacidad, serán recabados y tratados datos personales sensibles, tales como: información bancaria, situación financiera, fiscal y datos patrimoniales, para lo cual se le pedirá se consentimiento expreso.</p>

                        <p><strong>Ejercicio de los derechos de ARCO</strong></p>

                        <p>Usted tiene derecho de acceder a sus datos personales que poseemos y a los detalle del tratamiento de los mismos, así como rectificarlos en caso de ser inexactos o incompletos; cancelarlos cuando considere que no se requiere para alguna de las finalidades no consentidas o haya finalizado la relación contractual o del servicio, o bien, oponerse al tratamiento de los mismos para fines específicos.</p>
                    
                        <p>Los mecanismos que se han implementado para el ejercicio de dichos derechos son través de la presentación de la solicitud respectiva a la dirección de correo electrónico, <a href="mailto:inaki.lopez@construshop.mx">inaki.lopez@construshop.mx</a> o por escrito a la siguiente dirección; Bosque de lavanda, no. 60, int.
                        1, col. Bosque esmeralda, Atizapán de Zaragoza, C.P. 52930, Estado de México, Atención de protección de Datos personales.
                        </p>

                        <p>La solicitud deberá contener el nombre completo (Nombre o Nombres y apellidos), copia simple de su identificación oficial, o en medio electrónicos, versión digitalizada de la misma (escaneo), la descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de 
                        los derechos antes mencionados, indicación del correo electrónico o dirección física que designe para notificaciones y algún número telefónico de contacto.
                        </p>

                        <p>El plazo para atender su solicitud será en un máximo de veinte días contados a partir de la fecha de recepcion de su solicitud y se le dará respuesta por el mismo medio en que se reciba la solicitud.</p>
                    
                        <p><strong>Transferencia de datos Personales</strong></p>
                        <p>Los datos personales únicamente podrán ser transferidos a terceros mediante; (I) consentimiento expreso, (II) por resolución u orden de autoridad judicial o administrativa y (III) cuando se trate de información personal que por disposición de una ley sea considerada pública.</p>
                    
                        <p>Nos comprometemos a no transferir su información personal a terceros sin su consentimiento, y las excepciones previstas en el artículo 37 de la Ley federal de protección de Datos Personales en Posesión de los particulares, así como a realizar esta transferencia en los mismos términos que fija esa ley.</p>
                    
                        <p><strong>Modificaciones al Aviso de Privacidad</strong></p>

                        <p>Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad, para la atención de novedades legislativas, policitas internas o nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos.</p>
                        <p>Estas Modificaciones estarán disponibles al público a través de los siguientes medios: (I) anuncios visibles en nuestros establecimientos o centros de atención a clientes; (II) trípticos o folletos disponibles en nuestros establecimientos o centros de atención a clientes; (III) en nuestra página de internet [sección de privacidad]; (IV) o se las haremos llegar el último correo electrónico que nos haya proporcionado.
                        </p>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default Privacidad

export const Head = () => <title>Aviso de privacidad</title>